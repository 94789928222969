import React from 'react';

import { ISearchResultsControllerProps } from '../../platform/searchResultsControllerStore';
import { Widget } from '../Widget';
import { useHighlightStyle } from './useHighlightStyle';

export interface IWidgetRootProps extends ISearchResultsControllerProps {
  id: string;
}

export const WidgetRoot: React.FC<IWidgetRootProps> = React.memo((props) => {
  console.log('WidgetRoot:render', props); // eslint-disable-line

  const {
    apiErrorDetails,
    id,
    isDemoContent,
    locale,
    currency,
    onDocumentClick,
    onDocumentTypeChange,
    onPageChange,
    onProductAddToCart,
    onProductFacetsFilterChange,
    onQuerySubmit,
    onResetProductFacetsFilter,
    onSortChange,
    productFacets,
    documentTypes,
    searchSamples,
    searchRequest,
    searchRequestStatus,
    searchResponse,
    searchResponseTotals,
    searchResultsAbsoluteUrl,
    selectedSortOption,
    settings,
    viewMode,
  } = useHighlightStyle(props);

  return (
    <div className={id}>
      <Widget
        apiErrorDetails={apiErrorDetails}
        documentTypes={documentTypes}
        isDemoContent={isDemoContent}
        locale={locale}
        currency={currency}
        onProductAddToCart={onProductAddToCart}
        onProductFacetsFilterChange={onProductFacetsFilterChange}
        onDocumentClick={onDocumentClick}
        onDocumentTypeChange={onDocumentTypeChange}
        onResetProductFacetsFilter={onResetProductFacetsFilter}
        onPageChange={onPageChange}
        onSortChange={onSortChange}
        onQuerySubmit={onQuerySubmit}
        searchRequest={searchRequest}
        searchRequestStatus={searchRequestStatus}
        searchResponse={searchResponse}
        searchResponseTotals={searchResponseTotals}
        searchResultsAbsoluteUrl={searchResultsAbsoluteUrl}
        searchSamples={searchSamples}
        categoryList={settings.categoryList}
        selectedSortOption={selectedSortOption}
        viewMode={viewMode}
        productFacets={productFacets}
      />
    </div>
  );
});
